// AuthProvider.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";
import { $get } from "../utils/common";
import { useTranslation } from "react-i18next";
import Menu from "../layout/Menu.json";

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  const login = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setUserData(null);
    const res = (await $get("/api/logout")).data;
    if (res.success) {
      // setTimeout(() => {
      navigate(`/`);
      // refresh page
      window.location.reload();
      // }, 1000);
    }
  };

  const checkProjectRole = async (projectId) => {
    const res = await $get(`/api/project/check-role/${projectId}`);
    if (res.status === 200) {
      return res.data.hasAccess;
    }
  };

  const checkSystemRole = async (projectId) => {
    return userData.role_level === "system_admin";
  };

  const getProjectRole = async (projectId) => {
    const res = await $get(`/api/project/role/${projectId}`);
    if (res.status === 200) {
      return res.data.roleLevel;
    }
  };

  const checkLogin = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      const user = await $get(`/api/user`);
      login(user.data);

      // console.log("checkLogin", user.data);

      return true;

      // if (user.data.active_yn === "N") {
      //   navigate("/waiting-list");
      // }
    } else {
      setIsLoggedIn(false);
      setUserData(null);
      navigate("/");
      return false;
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/contact" &&
      location.pathname !== "/portfolio" &&
      location.pathname !== "/signup"
    ) {
      checkLogin();
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userData,
        setUserData,
        login,
        logout,
        checkLogin,
        checkProjectRole,
        checkSystemRole,
        getProjectRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
