import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import SystemSidebar from "../component/SystemSidebar";
import UserModal from "../component/UserModal";

function User() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/user/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
          searchText
        )}`
      : `/api/user/list/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        <SystemSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">사용자 관리</h1>
            </div>
            <div className="d-flex align-items-center gap-2 mb-4">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="이름 또는 이메일을 입력하세요."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (e.nativeEvent.isComposing) {
                        return;
                      }

                      getList();
                    }
                  }}
                />
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => getList()}
                >
                  <i className="fa fa-solid fa-search"></i>
                </button>
              </div>
              {/* <button className="btn btn-primary" type="button">
                <i className="fa fa-solid fa-plus me-2"></i>
                자재 추가
              </button> */}
            </div>
            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead>
                  <tr>
                    <th className="border-top-0">이메일</th>
                    <th className="border-top-0">이름</th>
                    <th className="border-top-0">연락처</th>
                    <th className="border-top-0">역할</th>
                    <th className="border-top-0">등록일</th>
                    <th className="border-top-0">활성화</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user, index) => (
                    <tr key={index}>
                      <td>{user.email}</td>
                      <td>{user.name}</td>
                      <td>{user.phone_number}</td>
                      <td>
                        {user.role_level === "system_admin"
                          ? "시스템 관리자"
                          : user.role_level === "company_admin"
                          ? "회사 관리자"
                          : "사용자"}
                      </td>
                      <td>{$dateTimezone(user.create_datetime)}</td>
                      <td>{user.active_yn}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary me-2"
                          onClick={() => {
                            setActiveUser(user);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa fa-solid fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="bx bx-chevron-left ms-n1 me-1" />
                          Prev
                        </a>
                      </li>
                    )}
                    {pageRange.map((page) => (
                      <li
                        key={page}
                        className={
                          pageNo === page
                            ? "page-item d-none d-sm-block active"
                            : "page-item d-none d-sm-block"
                        }
                      >
                        <a
                          onClick={(e) => setPageNo(page)}
                          className="page-link"
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          onClick={(e) => setPageNo(pageNo + 1)}
                          className="page-link"
                        >
                          Next
                          <i className="bx bx-chevron-right me-n1 ms-1" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <UserModal
          user={activeUser}
          onClose={() => {
            setShowModal(false);
            setActiveUser(null);
          }}
          onList={() => {
            getList();
            setActiveUser(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default User;
