import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function ProjectSidebar(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  const [projectRole, setProjectRole] = useState(null);

  const getProject = async (projectId) => {
    setLoading(true);
    const res = await $get(`/api/project/${projectId}`);
    if (res.status === 200) {
      setProject(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!props.projectId) {
      navigate("/project");
    }

    checkProjectRole(props.projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProject(props.projectId);
        getProjectRole(props.projectId).then((res) => {
          setProjectRole(res);
        });
      }
    });
  }, [props.projectId]);

  return (
    <aside className="col-lg-2 col-md-4 border-end pb-5 mt-n5">
      <div className="position-sticky top-0">
        <div className="text-center pt-5">
          <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
            <img
              src={
                project.img_src
                  ? `${serverUrl}/static/images/${project.img_src
                      .split("/")
                      .pop()}`
                  : `${appUrl}/assets/images/bg/house_blank2.png`
              }
              className="d-block"
            />
          </div>
          <h2 className="h5 mb-1">제주 애월 세컨하우스</h2>
          <p className="mb-3 pb-3">제주특별자치도 제주시 애월 192</p>
          <button
            type="button"
            className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
            data-bs-toggle="collapse"
            data-bs-target="#account-menu"
          >
            <i className="bx bxs-user-detail fs-xl me-2" />
            프로젝트 관리 메뉴
            <i className="bx bx-chevron-down fs-lg ms-1" />
          </button>
          <div
            id="account-menu"
            className="list-group list-group-flush collapse d-md-block"
          >
            <a
              onClick={() => navigate(`/project-inbox/${props.projectId}`)}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname.startsWith("/project-inbox") ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-envelope-open-text me-2"></i>
              프로젝트 Inbox
            </a>
            <a
              onClick={() => navigate(`/project-dashboard/${props.projectId}`)}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname.startsWith("/project-dashboard")
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-solid fa-chart-pie me-2"></i>
              대시보드
            </a>

            <a
              onClick={() => navigate(`/project-diary/${props.projectId}`)}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname.startsWith("/project-diary") ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-book me-2"></i>
              작업 일지
            </a>

            {/* <a
              href="account-security.html"
              className="list-group-item list-group-item-action d-flex align-items-center"
            >
              <i className="fa-solid fa-calendar-check me-2"></i>
              공정별 진행 현황
            </a> */}
            {/* {userData && userData.role_level !== "client" && (
              <a
                onClick={() => navigate(`/project-permit/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-permit")
                    ? "active"
                    : ""
                }`}
              >
                <i className="fa-regular fa-face-smile me-2"></i>
                인허가 관리
              </a>
            )} */}
            <a
              onClick={() => navigate(`/project-cost/${props.projectId}`)}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname.startsWith("/project-cost") ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-won-sign me-2"></i>
              비용 관리
            </a>
            <a
              onClick={() => navigate(`/project-document/${props.projectId}`)}
              className={`list-group-item list-group-item-action d-flex align-items-center ${
                location.pathname.startsWith("/project-document")
                  ? "active"
                  : ""
              }`}
            >
              <i className="fa-solid fa-file-contract me-2"></i>
              문서 관리
            </a>
            {projectRole && projectRole !== "client" && (
              <a
                onClick={() => navigate(`/project-material/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-material")
                    ? "active"
                    : ""
                }`}
              >
                <i className="fa-solid fa-tree me-2"></i>
                자재 투입 관리
              </a>
            )}
            {projectRole && projectRole !== "client" && (
              <a
                onClick={() => navigate(`/project-worker/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-worker")
                    ? "active"
                    : ""
                }`}
              >
                {/* <i className="fa-solid fa-person-digging me-2"></i> */}
                <i className="fa-solid fa-address-book me-2"></i>
                인력 투입 관리
              </a>
            )}
            {projectRole && projectRole !== "client" && (
              <a
                onClick={() => navigate(`/project-partner/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-partner")
                    ? "active"
                    : ""
                }`}
              >
                {/* <i className="fa-solid fa-person-digging me-2"></i> */}
                <i className="fa-solid fa-building me-2"></i>
                협력업체 관리
              </a>
            )}

            {projectRole && projectRole !== "client" && (
              <a
                onClick={() => navigate(`/project-member/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-member")
                    ? "active"
                    : ""
                }`}
              >
                <i className="fa-solid fa-users me-2"></i>
                멤버 초대하기
              </a>
            )}
            {projectRole && projectRole !== "client" && (
              <a
                onClick={() =>
                  navigate(`/project-ai-budget/${props.projectId}`)
                }
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-ai-budget")
                    ? "active"
                    : ""
                }`}
              >
                <i className="fa-solid fa-circle-dollar-to-slot me-2"></i>
                AI 예산 산정
              </a>
            )}
            {/* projectRole이 client가 아니거나 proejctRole이 client이고 project_status가 준공완료이면 종합리포트 메뉴 보이기 */}
            {projectRole &&
              (projectRole !== "client" ||
                (projectRole === "client" &&
                  project.project_status === "준공완료")) && (
                <a
                  onClick={() => navigate(`/project-report/${props.projectId}`)}
                  className={`list-group-item list-group-item-action d-flex align-items-center ${
                    location.pathname.startsWith("/project-report")
                      ? "active"
                      : ""
                  }`}
                >
                  <i className="fa-solid fa-chart-column me-2"></i>
                  종합리포트
                </a>
              )}
            {projectRole && projectRole !== "client" && (
              <a
                onClick={() => navigate(`/project-info/${props.projectId}`)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  location.pathname.startsWith("/project-info") ? "active" : ""
                }`}
              >
                <i className="fa-solid fa-house-circle-exclamation me-2"></i>
                프로젝트 정보
              </a>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default ProjectSidebar;
